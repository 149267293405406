import React, { useEffect, useState } from 'react';
import Typewriter from 'typewriter-effect';
import './styles.css';

const Valentine = () => {

    const [loggedIn, setLoggedIn] = useState(false);
    const [complete, setCompleted] = useState(false);
    const [showButtons, setShowButtons] = useState(false);
    const [showText, setShowText] = useState(false);
    const [showGif, setShowGif] = useState(false);
    const [showSecondGif, setShowSecondGif] = useState(false);
    const [showHelloText, setShowHelloText] = useState(false);
    

    const yay = () => {
        //alert("YOOOOOOOOO FR ?? YAYYY :DD");
        setCompleted(true);
    }

    useEffect(() => {
        const password = () => {
            while(true) {
                const pw = prompt("weirdo");
                if(pw.toLowerCase().includes("see phone")) {
                    setLoggedIn(!loggedIn);
                    setTimeout(() => {
                        setShowGif(true);
                    }, 0);
                    setTimeout(() => {
                        setShowText(true);
                    }, 2000);
                    break;
                }
            }
        }
        password();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (complete) {
            setTimeout(() => {
                setShowHelloText(true);
                setTimeout(() => {
                    setShowSecondGif(true);
                }, 4000);
            }, 1000);
        }
    }, [complete]);

    return (
        <>
            {showGif && (
                <div className="container">
                    {complete && (
                        <>
                            <img src="https://media.tenor.com/Ln8IqVQSpZMAAAAM/lele-iyi.gif" alt="yay" />
                         
                            {showHelloText && (
                                <Typewriter
                                    onInit={(typewriter) => {
                                        typewriter
                                            .typeString("YOOOOOOOOO FR ?? YAYYY :DD WELL HELLO, GIRLFRIEND LOL")
                                            .start();
                                    }}
                                />
                            )}
                            {showSecondGif && (
                                <img
                                    src="https://i.pinimg.com/originals/23/0a/e2/230ae258cd55fad29b36609752a356d5.gif"
                                    alt="second yay"
                                />
                            )}
                        </>
                    )}
                    {!complete && (
                        <>
                            <div>
                                {loggedIn && (
                                    <img
                                        src="https://media.tenor.com/WT7snNMnZoMAAAAM/luv-you-cute-kitten.gif"
                                        alt="cat"/>
                                )}
                            </div>
                            {showText && (
                                <Typewriter
                                    onInit={(typewriter) => {
                                        typewriter.typeString('angela, ')
                                            .callFunction(() => {
                                                console.log('String typed out!');
                                            })
                                            .pauseFor(500)
                                            .deleteChars(3)
                                            .typeString(',')
                                            .pauseFor(600)
                                            .typeString(' will u be my girlfriend ??')
                                            .callFunction(() => {
                                                setTimeout(() => {
                                                    setShowButtons(true);
                                                }, 1000)
                                            })
                                            .start();
                                    }}
                                />
                            )}
                            <br/>
                            {showButtons && (
                                <div className="buttons">
                                    <button className="yes" onClick={yay}>
                                        yes
                                    </button>
                                    <button className="ofc" onClick={yay}>
                                        ofc &lt;3
                                    </button>
                                </div>
                            )}
                        </>
                    )
                    }
                </div>
            )}
        </>
    );
};

export default Valentine;
